.fade-enter {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease-in-out opacity;
}
.fade-enter-active {
  opacity: 1;
  pointer-events: none;
}
.fade-enter-done {
  pointer-events: auto;
}
.fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out opacity;
}
.fade-exit-active {
  opacity: 0;
  pointer-events: none;
}

