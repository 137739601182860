/**
 |--------------------------------------------------
 | GLOBAL FONTS
 |--------------------------------------------------
 */

@font-face {
  font-family: 'metropolis-bold';
  src: local('Metropolis-Bold'),
    url(../assets/fonts/metropolis/Metropolis-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'metropolis-medium';
  src: local('Metropolis-Medium'),
    url(../assets/fonts/metropolis/Metropolis-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'metropolis-regular';
  src: local('Metropolis-Regular'),
    url(../assets/fonts/metropolis/Metropolis-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'metropolis-extra-bold';
  src: local('Metropolis-Extra-Bold'),
    url(../assets/fonts/metropolis/Metropolis-ExtraBold.otf) format('truetype');
}

@font-face {
  font-family: 'cormorant-garamond-bold';
  src: local('Cormorant-Garamond-Bold'),
    url(../assets/fonts/cormorant/CormorantGaramond-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'cormorant-medium-italic';
  src: local('Cormorant-Medium-Italic'),
    url(../assets/fonts/cormorant/Cormorant-MediumItalic.otf) format('truetype');
}

@font-face {
  font-family: 'goth-ball-crap';
  src: local('Goth-Ball-Crap'),
    url(../assets/fonts/goth_ball_crap/GothBallCrap.ttf) format('truetype');
}

@font-face {
  font-family: 'cyrillic-goth';
  src: local('Cyrillic-Goth'),
    url(../assets/fonts/cyryllic/CyrillicGoth.ttf) format('truetype');
}

@font-face {
  font-family: 'movavi-grotesque';
  src: local('Movavi-Grotesque'),
    url(../assets/fonts/movavi-grotesque/MovaviGrotesqueBlack.ttf) format('truetype');
}

/**
 |--------------------------------------------------
 | GLOBAL VARIABLES
 |--------------------------------------------------
 */

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}