.imgTransition-enter {
  position: absolute;
  opacity: 0;
  transition: 0.5s ease-in-out opacity;
}
.imgTransition-enter-active {
  position: absolute;
  opacity: 1;
}
.imgTransition-exit {
  position: absolute;
  transition: 0.5s ease-in-out opacity;
}
.imgTransition-exit-active {
  position: absolute;
  opacity: 0;
}
